define("Automatons/automatons/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "automatons",
    attributes: {
      name: "TDA-RI-Post_Chat_Survey",
      type: "satisfactionSurvey",
      description: "CMRASI-1487",
      initialNode: "node::2003",
      onPageLanding: function onPageLanding() {
        api.resize(360, 450);
      },
      style: "style::2005"
    }
  };
});