define("Automatons/automatons/2009", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2009,
    type: "automatons",
    attributes: {
      name: "Internal IMS Pre-chat",
      description: "CMRASI-2420",
      initialNode: "node::2024",
      style: "style::2009"
    }
  };
});