define("Automatons/automatons/2001", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2001,
    type: "automatons",
    attributes: {
      name: "TDA-RI-Prechat-PSD",
      automatonAttributes: {
        type: "guide",
        sub_type: "routing"
      },
      description: "CMRASI-1486",
      initialNode: "node::2001",
      style: "style::2004"
    }
  };
});