define("Automatons/automatons/2008", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2008,
    type: "automatons",
    attributes: {
      name: "External OLA Prechat",
      ariaEnabled: true,
      description: "CMRASI-2396",
      initialNode: "node::2022",
      style: "style::2007"
    }
  };
});