define("Automatons/nodes/2018", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2018,
    type: "nodes",
    attributes: {
      name: "Payment Services – Retirement Distributions - Chat",
      type: "chat",
      chatRouter: {
        agentAttributes: "Payment_Services,RETIREMENT_DISTRIBUTION",
        agentGroup: 10006349,
        agentsBusyNode: "node::2021",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2021"
      },
      isOutcomeNode: 1
    }
  };
});