define("Automatons/nodes/2024", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2024,
    type: "nodes",
    attributes: {
      name: "Internal IMS Pre-chat - Initial",
      type: "routing",
      header: "Welcome.",
      options: [{
        text: "IMS – Essential"
      }, {
        text: "IMS – Selective"
      }, {
        text: "IMS – Personalized"
      }],
      subheader: "Click an IMS queue to get specialized help.",
      template: "template::2012",
      transitions: {
        next: [{
          condition: "api.forms['internalIMSOption'].indexOf('IMS – Essential') > -1",
          target: "node::2025"
        }, {
          condition: "api.forms['internalIMSOption'].indexOf('IMS – Selective') > -1",
          target: "node::2027"
        }, {
          condition: "api.forms['internalIMSOption'].indexOf('IMS – Personalized') > -1",
          target: "node::2029"
        }]
      }
    }
  };
});