define("Automatons/automatons/2004", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2004,
    type: "automatons",
    attributes: {
      name: "External Prechat",
      ariaEnabled: true,
      description: "CMRASI-1962 CMRASI-2368",
      initialNode: "node::2007",
      style: "style::2007"
    }
  };
});