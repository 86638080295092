define("Automatons/nodes/2001", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2001,
    type: "nodes",
    attributes: {
      name: "TDA-RI-Prechat-PSD-initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          label: "What is your name?*",
          required: true,
          requiredError: "Please enter a valid answer"
        }, {
          id: "username",
          label: "What is your Prod-Am user name?*",
          required: true,
          requiredError: "Please enter a valid answer"
        }, {
          id: "product",
          label: "Application/Product you wish to have reset/unlocked*",
          required: true,
          requiredError: "Please enter a valid answer"
        }, {
          id: "type_of_service",
          type: "select",
          label: "What type of service requested*",
          options: ["Password Reset", "Account Unlock"],
          placeholder: "Select one",
          required: true,
          requiredError: "Please enter a valid answer"
        }, {
          id: "employee_id",
          dynamic: {
            check: "type_of_service",
            value: "Password Reset"
          },
          label: "Please provide your Employee ID*",
          required: true,
          requiredError: "Please enter a valid answer"
        }],
        heading: "Welcome.",
        next: "submit",
        requiredText: "*Required question",
        subheading: "To help us serve you better, please provide some information before we begin your chat."
      },
      template: "template::2001",
      transitions: {
        submit: {
          target: "node::2002"
        }
      }
    }
  };
});