define("Automatons/nodes/2007", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2007,
    type: "nodes",
    attributes: {
      name: "External Prechat - Initial",
      type: "survey",
      formify: {
        fields: [{
          id: "name",
          type: "text",
          label: "First name",
          required: true,
          requiredError: "Enter your first name.",
          validate: {
            maxlength: 20,
            maxlengthError: "Too many characters-limit 20."
          }
        }],
        heading: "Welcome to live chat.",
        next: "submit",
        subheading: "Please enter your first name to get started."
      },
      onEntry: function onEntry() {
        api.setTimeout(function () {
          api.showLayer("titleBar");
        }, 300);
      },
      onEntryTransition: "checkProactiveOverlay",
      onExit: function onExit() {
        if (api.automatonDataMap.ShowProactiveOverlay === false) {
          api.sendFormDataToAgent();
        }
      },
      template: "template::2006",
      transitions: {
        checkProactiveOverlay: {
          condition: "api.automatonDataMap.ShowProactiveOverlay === true",
          target: "node::2013"
        },
        submit: {
          target: "node::2008"
        }
      },
      viewport: {
        height: 400,
        position: {
          at: "right-85 bottom-85",
          my: "right bottom"
        },
        width: 350
      }
    }
  };
});