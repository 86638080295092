define("Automatons/nodes/2008", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2008,
    type: "nodes",
    attributes: {
      name: "External Prechat - Chat",
      type: "Chat",
      chatRouter: {},
      isOutcomeNode: 1,
      proto: "node::2010"
    }
  };
});