define("Automatons/nodes/2029", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2029,
    type: "nodes",
    attributes: {
      name: "Internal IMS Pre-chat - Personalized - Chat",
      type: "chat",
      chatRouter: {
        agentAttributes: "Internal_IMS,Personalized",
        agentGroup: 10006342,
        agentsBusyNode: "node::2030",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2030"
      },
      isOutcomeNode: 1
    }
  };
});