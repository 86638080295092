define("Automatons/nodes/2017", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2017,
    type: "nodes",
    attributes: {
      name: "Payment Services – Checks - Chat",
      type: "chat",
      chatRouter: {
        agentAttributes: "Payment_Services,CHECKS",
        agentGroup: 10006349,
        agentsBusyNode: "node::2020",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2020"
      },
      isOutcomeNode: 1
    }
  };
});