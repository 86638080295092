define("Automatons/nodes/2002", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2002,
    type: "nodes",
    attributes: {
      name: "TDA-RI-Prechat-PSD-thankyou",
      type: "chat",
      chatRouter: {
        agentGroup: 10006359,
        businessUnit: 19001158
      },
      isOutcomeNode: 1
    }
  };
});