define("Automatons/nodes/2012", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2012,
    type: "nodes",
    attributes: {
      name: "TDA-Retail Proactive Overlay - Close Button Clicked",
      type: "exit",
      isExitNode: 1,
      isOutcomeNode: 1,
      outcomeMessage: "Rejected"
    }
  };
});