define("Automatons/nodes/2030", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2030,
    type: "nodes",
    attributes: {
      name: "Internal IMS Pre-chat - Personalized - Agents Unavailable",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2013"
    }
  };
});