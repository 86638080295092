define("Automatons/nodes/2011", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2011,
    type: "nodes",
    attributes: {
      name: "TDA-Retail Proactive Overlay - Initial",
      type: "Targeted-Offer",
      onEntry: function onEntry() {
        api.hideLayer("titleBar");
        api.automatonDataMap.ShowProactiveOverlay = false;
      },
      template: "template::2008",
      viewport: {
        height: 280,
        width: 350
      }
    }
  };
});