define("Automatons/nodes/2003", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2003,
    type: "nodes",
    attributes: {
      name: "TDA-RI-Post_Chat_Survey-initial",
      type: "survey",
      formify: {
        fields: [{
          id: "satisfaction-rate",
          type: "select",
          label: "Overall, how satisfied are you with the service provided by the live chat representative you chatted with?",
          options: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
          placeholder: ""
        }, {
          id: "additional-comment",
          type: "textarea",
          label: "Please provide a comment to let us know how we can improve.",
          placeholder: "Type here...",
          validate: {
            maxlength: 500
          }
        }],
        heading: "Thank you for your time.",
        next: "submit",
        requiredText: "",
        subheading: "Your experience with our department is important to us. Please let us know how we did."
      },
      onEntry: function onEntry() {
        setTimeout(checkContainer, 300);

        function checkContainer() {
          var $viewport = api.automaton.Automaton.View.$viewport;

          var satisfactionSelect = $('select[name="satisfaction-rate"]', api.automaton.Automaton.View.$viewport);
          var commentsTextarea = $('textarea[name="additional-comment"]', api.automaton.Automaton.View.$viewport);
          var isResourceAgentGroup = api.IJSF.getAgentGroupName().toLowerCase().includes('resource');

          function checkFields() {
            if (['9', '10'].includes(satisfactionSelect.val()) || !['9', '10'].includes(satisfactionSelect.val()) && commentsTextarea.val() !== '') {
              $('.inq-2002-formify-submit', $viewport).removeAttr('disabled');
            } else {
              $('.inq-2002-formify-submit', $viewport).attr('disabled', 'disabled');
            }
          }

          if (isResourceAgentGroup) {
            satisfactionSelect.change(checkFields);
            commentsTextarea.change(checkFields);
          }
        }
      },
      template: "template::2001",
      transitions: {
        submit: {
          target: "node::2004"
        }
      }
    }
  };
});