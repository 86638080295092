define("Automatons/nodes/2013", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2013,
    type: "nodes",
    attributes: {
      name: "TDA-Retail External Proactive Overlay - Initial",
      type: "Targeted-Offer",
      onEntry: function onEntry() {
        api.hideLayer("titleBar");
        api.automatonDataMap.ShowProactiveOverlay = false;
      },
      template: "template::2009",
      viewport: {
        height: 310,
        position: "center center",
        width: 340
      }
    }
  };
});