define("Automatons/nodes/2010", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2010,
    type: "nodes",
    attributes: {
      name: "Chat Prototype",
      type: "Prototype",
      onEntry: function onEntry() {
        var formData = api.getFormData();
        var customerName = api.forms['name'] || 'You';

        var newFormData = formData + '\nCustomerDisplayName: ' + customerName + "\n";

        api.engageChat({
          formData: newFormData
        });
      }
    }
  };
});