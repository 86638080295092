define("Automatons/automatons/2003", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2003,
    type: "automatons",
    attributes: {
      name: "TD Ameritrade Post-Engagement Survey - New Scale",
      type: "satisfactionSurvey",
      ariaEnabled: true,
      description: "CMRASI-1834",
      initialNode: "node::2005",
      onPageLanding: function onPageLanding() {
        if (api.flashVars.deviceType === 'Standard') {
          api.resize('', 479);
        }
      },
      style: "style::2006"
    }
  };
});