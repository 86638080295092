define("Automatons/nodes/2004", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2004,
    type: "nodes",
    attributes: {
      name: "TDA-RI-Post_Chat_Survey-thankyou",
      type: "thankyou",
      isOutcomeNode: 1,
      template: "template::2003"
    }
  };
});