define("Automatons/automatons/2007", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2007,
    type: "automatons",
    attributes: {
      name: "Payment Services pre-chat",
      description: "CMRASI-2278",
      initialNode: "node::2015",
      style: "style::2009"
    }
  };
});