define("Automatons/nodes/2005", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2005,
    type: "nodes",
    attributes: {
      name: "TD Ameritrade Post-Engagement Survey - New Scale",
      type: "survey",
      formify: {
        fields: [{
          id: "tda-recommendation",
          type: "select",
          label: "1. Overall, how likely are you to recommend TD Ameritrade to friends, family, or colleagues?",
          options: ["0 - Not at all likely", "1", "2", "3", "4", "5", "6", "7 - Extremely likely"],
          placeholder: "Select"
        }, {
          id: "tda-service-satisfaction",
          type: "select",
          label: "2. How satisfied are you with the quality of service you received from your TD Ameritrade Chat Representative today?",
          options: ["0 - Not at all satisfied", "1", "2", "3", "4", "5", "6", "7 - Completely satisfied"],
          placeholder: "Select"
        }, {
          id: "tda-questions-satisfaction",
          type: "select",
          label: "3. Did the agent answer your questions to your satisfaction?",
          options: ["Yes", "No", "N/A - Don't know"],
          placeholder: "Select"
        }, {
          id: "tda-chat-replace-call",
          type: "select",
          label: "4. Did this chat replace the need to call us today?",
          options: ["Yes", "No", "N/A - Don't know"],
          placeholder: "Select"
        }],
        heading: "Thank you for your time.",
        next: "submit",
        requiredText: "",
        subheading: "Please take a moment to tell us what you think."
      },
      template: "template::2005",
      transitions: {
        submit: {
          target: "node::2006"
        }
      }
    }
  };
});