define("Automatons/nodes/2023", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2023,
    type: "nodes",
    attributes: {
      name: "External OLA Prechat - Chat",
      type: "Chat",
      chatRouter: {},
      isOutcomeNode: 1,
      proto: "node::2010"
    }
  };
});