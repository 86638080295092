define("Automatons/nodes/2015", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2015,
    type: "nodes",
    attributes: {
      name: "Payment Services pre-chat - Initial",
      type: "routing",
      header: "Welcome.",
      options: [{
        text: "Payment Services – ACH/Wire"
      }, {
        text: "Payment Services – Checks"
      }, {
        text: "Payment Services – Retirement Distributions"
      }],
      subheader: "Click a Payment Services queue below to get specialized help.",
      template: "template::2010",
      transitions: {
        next: [{
          condition: "api.forms['paymentServiceOption'].indexOf('Payment Services – ACH/Wire') > -1",
          target: "node::2016"
        }, {
          condition: "api.forms['paymentServiceOption'].indexOf('Payment Services – Checks') > -1",
          target: "node::2017"
        }, {
          condition: "api.forms['paymentServiceOption'].indexOf('Payment Services – Retirement Distributions') > -1",
          target: "node::2018"
        }]
      }
    }
  };
});