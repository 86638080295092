define("Automatons/nodes/2027", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2027,
    type: "nodes",
    attributes: {
      name: "Internal IMS Pre-chat - Selective - Chat",
      type: "chat",
      chatRouter: {
        agentAttributes: "Internal_IMS,Selective",
        agentGroup: 10006342,
        agentsBusyNode: "node::2028",
        businessUnit: 19001158,
        checkAgentAvailability: true,
        outsideHopNode: "node::2028"
      },
      isOutcomeNode: 1
    }
  };
});