define("Automatons/nodes/2021", ["exports", "module"], function (exports, module) {
  "use strict";

  module.exports = {
    id: 2021,
    type: "nodes",
    attributes: {
      name: "Payment Services – Retirement Distributions - Agents Unavailable",
      type: "self-help",
      isOutcomeNode: 1,
      template: "template::2011"
    }
  };
});